<script setup>
import "@/assets/scss/_antd-drawer.scss";
import { Drawer } from "ant-design-vue";

const ADrawer = Drawer;

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:visible"]);

watch(
  () => props.visible,
  (value) => {
    emit("update:visible", value);
  }
);

const collapseProduct = ref(Array.from({ length: 5 }, () => true));
</script>

<template>
  <a-drawer
    :visible="visible"
    :bodyStyle="{ paddingTop: '45px' }"
    :contentWrapperStyle="{ width: '100vw' }"
    body
    :closable="false"
    placement="right"
  >
    <template #title>
      <div class="flex items-center justify-between">
        <nuxt-link to="/">
          <nuxt-picture loading="lazy" src="/images/logo.png" alt="logo" width="173" :img-attrs="{ alt: 'logo' }" />
        </nuxt-link>

        <button class="small !px-2 bg-[#F0F0F0] border-none" @click="visible = false">
          <svg viewBox="0 0 329.26933 329" width="20px" height="20px">
            <path
              d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
            />
          </svg>
        </button>
      </div>
    </template>

    <div class="py-3">
      <nuxt-link to="/gioi-thieu" class="text-primary font-bold">Giới thiệu</nuxt-link>
    </div>

    <!-- <div class="py-3">
      <nuxt-link to="/san-pham" class="text-primary font-bold">Sản phẩm</nuxt-link>
    </div> -->

    <div>
      <div
        class="py-3 border-solid border-b border-[#E0E0E0] flex items-center justify-between"
        @click="collapseProduct[1] = !collapseProduct[1]"
      >
        <span class="text-primary font-bold">Giải pháp</span>
        <svg
          viewBox="0 0 24 24"
          width="20px"
          height="20px"
          class="fill-[#0066e6] transition-all"
          :class="{ 'rotate-180 ': !collapseProduct[1] }"
        >
          <g>
            <path
              d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
            />
          </g>
        </svg>
      </div>
      <div
        class="sub-menu flex flex-col py-2 overflow-hidden transition-all"
        :class="{ '!h-0 !py-0': collapseProduct[1] }"
      >
        <div class="sub-menu-container">
          <div class="sub-menu-item">Kênh Offline to Online</div>
          <nuxt-link to="/giai-phap/digital-signage" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-ds.svg"
              :img-attrs="{ alt: 'Digital Signage' }"
            />
            <div>Digital Signage</div>
          </nuxt-link>
          <nuxt-link to="/giai-phap/self-service-kiosk" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-ssk.svg"
              :img-attrs="{ alt: 'Self-service Kiosk' }"
            />
            <div>Self-service Kiosk</div>
          </nuxt-link>
          <nuxt-link to="/giai-phap/he-thong-xep-hang-tu-dong" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-sq.svg"
              :img-attrs="{ alt: 'Đặt lịch xếp hàng' }"
            />
            <div>Đặt lịch xếp hàng</div>
          </nuxt-link>
          <nuxt-link to="/giai-phap/vms-camera" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-vms-camera.svg"
              :img-attrs="{ alt: 'VMS - AI Camera' }"
            />
            <div>VMS - AI Camera</div>
          </nuxt-link>
          <nuxt-link to="/giai-phap/so-hoa-tai-lieu" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-sq.svg"
              :img-attrs="{ alt: 'Số hóa tài liệu' }"
            />
            <div>Số hóa tài liệu</div>
          </nuxt-link>
          <nuxt-link to="/giai-phap/trung-tam-du-lieu" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-cx.svg"
              :img-attrs="{ alt: 'Trung tâm dữ liệu' }"
            />
            <div>Trung tâm dữ liệu</div>
          </nuxt-link>
        </div>
        <div class="sub-menu-container">
          <div class="sub-menu-item cursor-default">Kênh Online</div>
          <nuxt-link to="/giai-phap/cdp" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-cdp.svg"
              :img-attrs="{ alt: 'CDP - Nền tảng phân tích dữ liệu khách hàng' }"
            />
            <div>CDP - Nền tảng phân tích dữ liệu khách hàng</div>
          </nuxt-link>
          <nuxt-link to="/giai-phap/quan-ly-tai-san" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-qlts.svg"
              :img-attrs="{ alt: 'Quản lý tài sản' }"
            />
            <div>Quản lý tài sản</div>
          </nuxt-link>
          <nuxt-link to="/giai-phap/e-form" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-eform.svg"
              :img-attrs="{ alt: 'Biểu mẫu điện từ E-Form' }"
            />
            <div>Biểu mẫu điện từ E-Form</div>
          </nuxt-link>
          <nuxt-link to="/giai-phap/cx" class="sub-menu-item">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-cx.svg"
              :img-attrs="{ alt: 'Trải nghiệm khách hàng đa kênh' }"
            />
            <div>Trải nghiệm khách hàng đa kênh</div>
          </nuxt-link>
          <nuxt-link to="https://qrx.com.vn/" class="sub-menu-item" target="_blank" @click="visible = false">
            <nuxt-picture
              loading="lazy"
              class="shrink-0"
              src="/images/icons/ic-solution-qr.svg"
              :img-attrs="{ alt: 'QRX - Nền tảng kinh doanh D2C' }"
            />
            <div>QRX - Nền tảng kinh doanh D2C</div>
          </nuxt-link>
        </div>
      </div>
    </div>

    <div>
      <div
        class="py-3 border-solid border-b border-[#E0E0E0] flex items-center justify-between"
        @click="collapseProduct[2] = !collapseProduct[2]"
      >
        <span class="text-primary font-bold">Tin tức & Sự kiện</span>
        <svg
          viewBox="0 0 24 24"
          width="20px"
          height="20px"
          class="fill-[#0066e6] transition-all"
          :class="{ 'rotate-180 ': !collapseProduct[2] }"
        >
          <g>
            <path
              d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
            />
          </g>
        </svg>
      </div>
      <div
        class="sub-menu flex flex-col py-2 overflow-hidden transition-all"
        :class="{ '!h-0 !py-0': collapseProduct[2] }"
      >
        <div class="sub-menu-container">
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/tin-tuc/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
            ><div class="font-bold text-black">Tin tức</div></nuxt-link
          >
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/cau-chuyen-khach-hang/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
            ><div>Câu chuyện khách hàng</div></nuxt-link
          >
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/truyen-thong-noi-ve-miraway/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
            ><div>Truyền thông nói về Miraway</div></nuxt-link
          >
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/su-kien/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
            ><div>Sự kiện</div></nuxt-link
          >
        </div>
        <div class="sub-menu-container">
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div class="font-bold text-black">Blog</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/digital-signage/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Digital signage</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/he-thong-xep-hang-tu-dong/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Hệ thống xếp hàng tự động</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/kiosk-tu-phuc-vu/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Kiosk tự phục vụ</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/cdp/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Giải pháp CDP</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/e-form/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>E form</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/he-thong-khao-sat-phan-hoi/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Hệ thống khảo sát phản hồi</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/he-thong-loyalty/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Hệ thống Loyalty</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/he-thong-nhan-dien-khuon-mat/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Hệ thống nhận diện khuôn mặt</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/customer-centric/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Customer centric</div>
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/trai-nghiem-khach-hang/"
            class="sub-menu-item"
            target="_blank"
            @click="visible = false"
          >
            <div>Trải nghiệm khách hàng</div>
          </nuxt-link>
        </div>
      </div>
    </div>

    <div class="py-3">
      <nuxt-link to="/tuyen-dung" class="text-primary font-bold">Cơ hội nghề nghiệp</nuxt-link>
    </div>
    <div class="py-3">
      <nuxt-link to="/lien-he" class="text-primary font-bold">Liên hệ</nuxt-link>
    </div>
  </a-drawer>
</template>
